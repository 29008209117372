import React from 'react'

const User = ({ width = '20px', height = '20px', color = '#000', ...rest }) => (
  <svg viewBox="0 0 20 20" width={width} height={height} {...rest}>
    <path
      fill={color}
      d="M13.3 11.5H6.7c-1.2 0-2.2.5-3.1 1.3-.8.8-1.3 1.9-1.3 3.1v1.7h2v-1.7c0-.6.2-1.2.7-1.6.4-.4 1-.7 1.7-.7h6.7c1.3 0 2.3 1 2.3 2.3v1.7h2v-1.7c0-2.5-2-4.4-4.4-4.4zM10 10.2c2.4 0 4.3-1.9 4.3-4.3S12.4 1.5 10 1.5 5.7 3.4 5.7 5.8s1.9 4.4 4.3 4.4zm0-6.7c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3z"
    />
  </svg>
)

export default User
