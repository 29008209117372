import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import theme from 'styles/themes'

import HeadlineBase from './HeadlineBase'

export const headline6Styles = css`
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.15px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    line-height: 24px;
    ${({ scaleDownOnMobile }) =>
      scaleDownOnMobile
        ? css`
            font-size: 16px;
          `
        : css`
            font-size: 18px;
          `}
  }
`

const Headline6 = styled(HeadlineBase)`
  ${headline6Styles};
`

export default defaultProps({
  as: 'h6',
})(Headline6)
