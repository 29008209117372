import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from 'styles/themes'

import HeadlineBase from './HeadlineBase'

export const headline4Styles = css`
  font-size: 34px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 30px;
    line-height: 1.07;
    letter-spacing: 0.25px;
  }
`

const Headline4 = styled(HeadlineBase)`
  ${headline4Styles};
`

export default defaultProps({
  as: 'h4',
})(Headline4)
