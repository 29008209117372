import styled from 'styled-components'

import theme from 'styles/themes'

import { HeadlineBase } from 'components/Texts'

export const HeadlinesWrapper = styled.div`
  background-color: ${theme.Colors.light};
  width: 100%;
  padding: 64px 0;
`

export const HeadlinesInnerWrapper = styled.div`
  max-width: 753px;
`

export const SiteHeadline = styled(HeadlineBase)`
  font-size: 64px;
  font-weight: 800;
  line-height: 1.13;
  letter-spacing: -1px;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 34px;
    line-height: 1.18;
    letter-spacing: -1.2px;
  }
`
