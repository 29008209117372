import theme from 'styles/themes'

export const heroBackgroundResolver = (
  isJobPlatformBox,
  isBookingPlatformBox,
) => {
  if (isJobPlatformBox) {
    return theme.Colors.secondary2
  }

  if (isBookingPlatformBox) {
    return theme.Colors.background2
  }

  return theme.Colors.background2
}

export const textColorResolver = (isJobPlatformBox, isBookingPlatformBox) => {
  if (isJobPlatformBox || isBookingPlatformBox) return theme.Colors.background

  return '#081f2d'
}
