import styled from 'styled-components'
import { Box } from '@rebass/grid'

import { theme } from '../../styles/themes'

const TypoBase = styled(Box)`
  word-break: break-word;
  color: ${props => props.color};
  display: ${props => (props.fullWidth ? 'flex' : 'inline-flex')};
  ${({ gray }) => gray && `color: ${theme.Colors.mediumBlack}`};
  ${({ white }) => white && `color: ${theme.Colors.light}`};
  ${({ green }) => green && `color: ${theme.Colors.secondary}`};
  ${({ disabled }) => disabled && `color: ${theme.Colors.disabledBlack}`};
  ${({ uppercase }) => uppercase && `text-transform: uppercase`};
  ${({ themeColor }) => themeColor && `color: ${theme.Colors[themeColor]}`};
  ${({ center }) => center && 'text-align: center;'};

  :hover {
    ${({ interactive }) => interactive && `cursor: pointer;`};
  }
`

export default TypoBase
