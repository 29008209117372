import React, { Fragment } from 'react'

import { Body1, ButtonText } from 'components/Texts'
import { NoStylesExternalLink } from 'components/Links'

import {
  HeroWrapper,
  HeroBox,
  HeroTitle,
  HeroButton,
  HeroBoxInnerWrapper,
  HeroBoxContentWrapper,
  ImageMobile,
} from './styledComponents'
import { heroBackgroundResolver, textColorResolver } from './utils'
import {
  JOB_PLATFORM_TITLE_NAME,
  HERO_BUTTONS_MAPPER,
  BOOKING_PLATFORM_TITLE_NAME,
} from './consts'

const Hero = ({ heroBoxes, isMobile }) => (
  <HeroWrapper>
    {heroBoxes
      .sort((a = {}, b = {}) => a.position - b.position)
      .map(
        ({
          title,
          header,
          subHeader,
          heroImage,
          heroImageMobile,
          isEnabled,
          buttonText,
        }) => {
          const isJobPlatformBox = title === JOB_PLATFORM_TITLE_NAME
          const isBookingPlatformBox = title === BOOKING_PLATFORM_TITLE_NAME
          const { href, backgroundColor, color } =
            HERO_BUTTONS_MAPPER[title] || {}

          const ActionButtonWrapperComponent = isEnabled
            ? NoStylesExternalLink
            : Fragment

          return (
            <HeroBox
              color={textColorResolver(isJobPlatformBox, isBookingPlatformBox)}
              background={heroBackgroundResolver(
                isJobPlatformBox,
                isBookingPlatformBox,
              )}
              backgroundImage={isMobile ? '' : heroImage.fluid.src}
              key={header}
            >
              <HeroBoxInnerWrapper>
                {isMobile && (
                  <ImageMobile
                    alt=""
                    fluid={heroImageMobile.fluid}
                    imgStyle={{
                      objectFit: 'contain',
                      width: 'auto',
                      left: 'unset',
                      right: '0',
                    }}
                  />
                )}
                <HeroBoxContentWrapper>
                  <HeroTitle mb="16px">{header}</HeroTitle>
                  <Body1 mb="36px">{subHeader}</Body1>
                  <ActionButtonWrapperComponent {...(isEnabled && { href })}>
                    <HeroButton
                      disabled={!isEnabled}
                      backgroundColor={backgroundColor}
                      color={color}
                    >
                      <ButtonText color="inherit">{buttonText}</ButtonText>
                    </HeroButton>
                  </ActionButtonWrapperComponent>
                </HeroBoxContentWrapper>
              </HeroBoxInnerWrapper>
            </HeroBox>
          )
        },
      )}
  </HeroWrapper>
)

export default Hero
