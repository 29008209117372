import styled from 'styled-components'
import { styled as materialStyled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { theme } from 'styles/themes'

import { resolveButtonPadding } from './utils'

export const ArticlesWrapper = styled.div`
  margin: 192px 0;
  width: 100%;

  li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: ${theme.Breakpoints.mobile}) {
    margin: 80px 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
  margin-bottom: 32px;
  :first-of-type {
    margin-left: -32px;
  }

  @media (max-width: ${theme.Breakpoints.mobile}) {
    max-width: 316px;

    :first-of-type {
      margin-left: -8px;
    }
  }
`

export const CoverImage = styled.div`
  border-radius: 3px;
`

export const ArticleButton = materialStyled(Button)({
  backgroundColor: theme.Colors.secondary,
  padding: ({ hasSmallPadding, isMobile }) =>
    resolveButtonPadding({ hasSmallPadding, isMobile }),
  borderRadius: ({ isLessRounded }) => (isLessRounded ? '2px' : '4px'),
  fontSize: '16px',
  height: '48px',
  color: theme.Colors.background,
  minWidth: 'initial',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: theme.Colors.secondary600,
  },
  '&:first-of-type': {
    marginRight: '8px',
  },
})
