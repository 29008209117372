import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { Body1 } from 'components/Texts'
import Layout, { Inner } from 'components/Layout'
import Hero from 'components/Hero'
import Articles from 'components/Articles'
import { COOKIE_PRO_ID } from 'consts'

import { useMatchMedia } from 'hooks'

import {
  HeadlinesWrapper,
  HeadlinesInnerWrapper,
  SiteHeadline,
} from './styledComponents'

const MainTemplate = ({ data }) => {
  const { isMobile, isTablet } = useMatchMedia()

  const siteTitle = _.get(data, 'contentfulLandingPageTitle.title')
  const siteSubtitle = _.get(
    data,
    'contentfulLandingPageTitle.childContentfulLandingPageTitleSubtitleTextNode.subtitle',
  )
  const configSiteTitle = _.get(data, 'site.siteMetadata.title')
  const newestPosts = _.get(data, 'allContentfulBlogPost.nodes')
  const heroBoxes = _.get(data, 'allContentfulHeroBox.nodes')
  const placeholderImage = _.get(data, 'file.childImageSharp.fluid')
  const backgroundImg = _.get(
    data,
    'allContentfulLandingBackground.nodes[0].background',
  )

  const { siteTitle: siteTitleMetaTag, description, author, keywords } = _.get(
    data,
    'contentfulWebPageMetaTags',
    {},
  )

  return (
    <Layout>
      <Helmet>
        <script
          src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={COOKIE_PRO_ID}
        ></script>
        <script type="text/javascript" id="zsiqchat">
          {`var $zoho = $zoho || {};
          $zoho.salesiq = $zoho.salesiq || { widgetcode: "b0c8094d68b339d23bad8e783f555510aca2c0aa1267716846062a6abc4e8b120b5e354ee404cab7a41e04eb58edaf7d", values:{},ready:function(){}};
          var d=document;s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="https://salesiq.zoho.eu/widget";
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);`}
        </script>
        <script type="text/javascript">function OptanonWrapper() {}</script>
        <title>{siteTitleMetaTag || configSiteTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <HeadlinesWrapper>
        <Inner>
          <HeadlinesInnerWrapper>
            <SiteHeadline>{siteTitle}</SiteHeadline>
            <Body1 mt="16px">{siteSubtitle}</Body1>
          </HeadlinesInnerWrapper>
        </Inner>
      </HeadlinesWrapper>

      <Hero {...{ backgroundImg, heroBoxes, isMobile, isTablet }} />

      <Inner>
        <Articles {...{ isMobile, newestPosts, placeholderImage }} />
      </Inner>
    </Layout>
  )
}

export default MainTemplate

export const mainQuery = graphql`
  query MainQuery {
    site {
      siteMetadata {
        title
      }
    }

    file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    contentfulWebPageMetaTags(page: { eq: "landing" }) {
      author
      description
      keywords
      siteTitle
    }
    contentfulLandingPageTitle {
      title
      childContentfulLandingPageTitleSubtitleTextNode {
        subtitle
      }
    }
    allContentfulBlogPost(
      limit: 6
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        publishDate
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        heroImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        slug
        tags
      }
    }

    allContentfulHeroBox(sort: { fields: position, order: ASC }) {
      nodes {
        id
        title
        position
        header
        subHeader
        heroImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        heroImageMobile {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        buttonText
        isEnabled
      }
    }

    allContentfulLandingBackground(limit: 1) {
      nodes {
        background {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
