import styled, { css } from 'styled-components'
import { defaultProps, withProps, compose } from 'recompose'

import BodyBase from './BodyBase'

export const overlineStyles = css`
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`

const Overline = styled(BodyBase)`
  ${overlineStyles};
`

export default compose(
  withProps({
    medium: true,
  }),
  defaultProps({
    as: 'span',
  }),
)(Overline)
