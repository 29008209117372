import React, { createContext, useContext, useState, useEffect } from 'react'

import { useMatchMedia } from 'hooks'

const LayoutContext = createContext()

const LayoutContextProvider = ({ children, ...props }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { isMobile, isTablet } = useMatchMedia()

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (!(isMobile || isTablet)) {
      setIsMobileMenuOpen(false)
    }
  }, [isMobile, isTablet])

  return (
    <LayoutContext.Provider
      value={{
        isMobileMenuOpen,
        toggleMobileMenu,
      }}
      {...props}
    >
      {children}
    </LayoutContext.Provider>
  )
}
const useLayout = () => useContext(LayoutContext)

export { LayoutContextProvider, useLayout }
