import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from 'styles/themes'

import HeadlineBase from './HeadlineBase'

export const headline3Styles = css`
  font-size: 48px;
  line-height: 1.17;
  ${({ scaleDownOnDesktop }) =>
    scaleDownOnDesktop &&
    css`
      font-size: 40px;
      line-height: 1.2;
    `}
    
  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 36px;
    line-height: 1.11;
    ${({ scaleDownOnMobile }) =>
      scaleDownOnMobile &&
      css`
        font-size: 24px;
        font-weight: 800;

        line-height: 1.33;
        letter-spacing: -0.4px;
      `}
  }
`

const Headline3 = styled(HeadlineBase)`
  ${headline3Styles};
`

export default defaultProps({
  as: 'h3',
})(Headline3)
