import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import BodyBase from './BodyBase'

export const subtitle2Styles = css`
  font-size: 14px;
  line-height: 1.71;
`

const Subtitle2 = styled(BodyBase)`
  ${subtitle2Styles};
`

export default defaultProps({
  as: 'p',
})(Subtitle2)
