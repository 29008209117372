import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import BodyBase from './BodyBase'

export const body1Styles = css`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
`

const Body1 = styled(BodyBase)`
  ${body1Styles};

  ${props => props.block && `display: block;`};
`

export default defaultProps({
  as: 'p',
})(Body1)
