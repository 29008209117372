import _ from 'lodash'

import { BLOG_URL } from 'consts'

export const generateBlogPostPath = slug => {
  const blogPrefixPart =
    _.last(BLOG_URL) === '/' ? BLOG_URL.slice(0, -1) : BLOG_URL
  const slugSuffixPart = _.first(slug) === '/' ? slug.slice(1) : slug

  return `${blogPrefixPart}/blog/${slugSuffixPart}`
}

export const resolveButtonPadding = ({ hasSmallPadding, isMobile }) => {
  if (hasSmallPadding) return '14px'
  if (isMobile) return '16px 36px'

  return '16px 72px'
}
