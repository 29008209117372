import styled, { css } from 'styled-components'

import { theme } from '../../styles/themes'

import TypoBase from './TypoBase'

export const headlineBaseStyles = css`
  font-family: ${theme.Fonts.Family.headline};
  font-weight: ${({ bold, extraBold }) =>
    (bold && theme.Fonts.FontWeight.bold) ||
    (extraBold && theme.Fonts.FontWeight.extraBold) ||
    theme.Fonts.FontWeight.regular};
`
const HeadlineBase = styled(TypoBase)`
  ${headlineBaseStyles}
`

export default HeadlineBase
