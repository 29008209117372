import React, { useState, useMemo } from 'react'
import Carousel, {
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from '@brainhubeu/react-carousel'
import { Flex } from '@rebass/grid'

import { Headline3 } from 'components/Texts'
import { PrevArrow, NextArrow } from 'components/Icons'

import '@brainhubeu/react-carousel/lib/style.css'

import Translation from 'utils/Translation'
import { BLOG_URL } from 'consts'
import theme from 'styles/themes'

import Article from './Article'
import { ArticlesWrapper, ArticleButton } from './styledComponents'

const Articles = ({ newestPosts, placeholderImage, isMobile }) => {
  const [activeItemNumber, setActiveItemNumber] = useState(0)

  const isFirstItemActive = activeItemNumber === 0
  const isLastItemActive = activeItemNumber === newestPosts.length - 1

  const SLIDES = useMemo(
    () =>
      newestPosts.map(({ heroImage, slug, title, description, tags = [] }) => (
        <Article
          {...{
            heroImage,
            slug,
            title,
            description,
            tags,
            placeholderImage,
            isMobile,
          }}
        />
      )),
    [newestPosts],
  )

  return (
    <ArticlesWrapper>
      <Headline3
        mb={isMobile ? '24px' : '40px'}
        scaleDownOnDesktop
        scaleDownOnMobile
        extraBold
        as="h3"
      >
        <Translation id="landing.news" />
      </Headline3>

      <Carousel
        offset={isMobile ? 16 : 64}
        itemWidth={isMobile ? 316 : 445}
        value={activeItemNumber}
        onChange={value => setActiveItemNumber(value)}
        plugins={[
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: isMobile ? 2 : 3,
            },
          },
          {
            resolve: slidesToScrollPlugin,
            options: {
              numberOfSlides: 1,
            },
          },
        ]}
        slides={SLIDES}
      />

      <Flex justifyContent="space-between">
        <Flex>
          <ArticleButton
            hasSmallPadding
            isLessRounded
            onClick={() => {
              if (!isFirstItemActive) {
                setActiveItemNumber(value => value - 1)
              }
            }}
          >
            <PrevArrow
              {...(isFirstItemActive && { color: theme.Colors.disabledWhite })}
            />
          </ArticleButton>
          <ArticleButton
            hasSmallPadding
            isLessRounded
            onClick={() => {
              if (!isLastItemActive) {
                setActiveItemNumber(value => value + 1)
              }
            }}
          >
            <NextArrow
              {...(isLastItemActive && { color: theme.Colors.disabledWhite })}
            />
          </ArticleButton>
        </Flex>
        <ArticleButton isMobile={isMobile} href={BLOG_URL}>
          <Translation id="landing.seeAll" />
        </ArticleButton>
      </Flex>
    </ArticlesWrapper>
  )
}

export default Articles
