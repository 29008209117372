import React from 'react'

import theme from 'styles/themes'

const NextArrow = ({
  width = '20px',
  height = '20px',
  color = theme.Colors.background,
  ...props
}) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" {...props}>
    <path stroke={color} strokeWidth={2} d="M3.333 10h13.334m-5-5l5 5-5 5" />
  </svg>
)

export default NextArrow
