import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import BodyBase from './BodyBase'

export const captionStyles = css`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
`

const Caption = styled(BodyBase)`
  ${captionStyles};
`

export default defaultProps({
  as: 'span',
})(Caption)
