import React from 'react'

import { NoStylesLink, NoStylesExternalLink } from './styledComponents'

const DualPurposeAnchor = ({
  Anchor = NoStylesExternalLink,
  LinkComponent = NoStylesLink,
  ...props
}) => (props.href ? <Anchor {...props} /> : <LinkComponent {...props} />)

export default DualPurposeAnchor
