import React from 'react'

import GTMTag from './GTMTag'
import { useCookieWatcher } from './useCookieWatcher'

const Cookies = () => {
  const { exist: isCookieAccepted } = useCookieWatcher('OptanonAlertBoxClosed')

  return <>{isCookieAccepted && <GTMTag />}</>
}

export default Cookies
