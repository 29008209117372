import { useState, useEffect } from 'react'

const cookies = () =>
  Object.fromEntries(document.cookie.split(';').map(it => it.trim().split('=')))

// eslint-disable-next-line no-prototype-builtins
const cookieExist = cookie => cookies().hasOwnProperty(cookie)

export const useCookieWatcher = (cookie, pollingRate = 250) => {
  // state for cookie existence
  const [exist, setExist] = useState(cookieExist(cookie))

  useEffect(() => {
    const interval = setInterval(
      () => setExist(cookieExist(cookie)),
      pollingRate,
    )
    return () => clearInterval(interval)
  })

  return { exist }
}
