import styled, { css } from 'styled-components'
import { styled as materialStyled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { theme } from 'styles/themes'

export const Wrapper = styled.div`
  position: relative;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`

export const FloatingBox = styled.div`
  box-shadow: ${theme.Elevations.dp1};
  background-color: ${theme.Colors.background};

  min-width: 200px;
  z-index: 10;
  position: absolute;
  width: auto;
  ${({ alignToLeft }) => (alignToLeft ? `right: 0px` : `left: 0`)};

  top: ${({ topPosition }) => topPosition || '38px'};
`

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  ${({ applyDefaultStyling = true, isDisabled }) =>
    applyDefaultStyling &&
    css`
      padding: 12px 16px;
      color: ${theme.Colors.mediumBlack};
      transition: ${theme.Transitions.default};

      :not(:first-child) {
        border-top: 1px solid ${theme.Colors.light};
      }

      ${!isDisabled &&
        css`
          cursor: pointer;
          :hover {
            background-color: ${theme.Colors.light};
            color: ${theme.Colors.highBlack};
          }
        `}
    `}
`

export const DefaultMainBarButton = materialStyled(Button)({
  backgroundColor: ({ isOpened }) =>
    isOpened ? theme.Colors.secondary600 : theme.Colors.secondary,
  padding: '0 24px',
  fontSize: '16px',
  height: '40px',
  color: theme.Colors.background,
  '&:hover': {
    backgroundColor: ({ isOpened }) =>
      isOpened ? theme.Colors.secondary600 : theme.Colors.secondary,
  },
})
