import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from 'styles/themes'

import HeadlineBase from './HeadlineBase'

export const headline5Styles = css`
  font-size: 24px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 20px;
    line-height: 1.2;
  }
`

const Headline5 = styled(HeadlineBase)`
  ${headline5Styles};
`

export default defaultProps({
  as: 'h5',
})(Headline5)
