import React from 'react'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import { PAGE_INTERNAL_URL } from 'consts'
import { theme } from 'styles/themes'
import { useMatchMedia } from 'hooks'
import Translation from 'utils/Translation'

import { SECIcon, User, Close } from 'components/Icons'
import { NoStylesLink } from 'components/Links'
import { Headline6 } from 'components/Texts'

import {
  HeaderWrapper,
  Inner,
  LogoWrapper,
  DesktopHideWrapper,
  MobileHideWrapper,
  MobileIconWrapper,
  InternalHeaderWrapper,
} from './styledComponents'
import { AppLinks, MobileMenu } from './components'
import { useLayout } from './context'
import { UserManageLinks } from './components/UserManageLinks'

const Header = () => {
  const { isMobileMenuOpen, toggleMobileMenu } = useLayout()
  const { isMobile, isTablet } = useMatchMedia()

  const {
    allContentfulHeaderDropdown: { nodes: headerDropdowns = [] } = {},
  } = useStaticQuery(graphql`
    query HeaderDropdownsQuery {
      allContentfulHeaderDropdown(filter: { platform: { in: "landing" } }) {
        nodes {
          title
          items {
            href
            to
            label
          }
        }
      }
    }
  `)

  const MobileMenuIcon = isMobileMenuOpen ? Close : User

  return (
    <HeaderWrapper bg={theme.Colors.light}>
      <Inner>
        <InternalHeaderWrapper>
          {isMobileMenuOpen && (isMobile || isTablet) ? (
            // Mobile menu header
            <Headline6 scaleDownOnMobile bold>
              <Translation id="header.menu" />
            </Headline6>
          ) : (
            // Header logo
            <NoStylesLink
              to={PAGE_INTERNAL_URL}
              css={`
                svg {
                  width: 4.9em;
                  font-size: 30px;
                }
              `}
            >
              <LogoWrapper>
                <SECIcon />
              </LogoWrapper>
            </NoStylesLink>
          )}

          {/* Desktop Menu */}
          <MobileHideWrapper isTabletIncluded width="100%" marginLeft="48px">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              {/* Left links */}
              <AppLinks />

              {/* Right links */}
              <UserManageLinks headerDropdowns={headerDropdowns} />
            </Flex>
          </MobileHideWrapper>

          {/* Mobile menu */}
          <DesktopHideWrapper
            isTabletNotIncluded
            css={`
              color: ${theme.Colors.mediumBlack};
            `}
          >
            <MobileIconWrapper ml="20px" isInteractive>
              <MobileMenuIcon
                width="20px"
                height="20px"
                color={theme.Colors.mediumBlack}
                onClick={() => toggleMobileMenu()}
              />
            </MobileIconWrapper>
            <MobileMenu headerDropdowns={headerDropdowns} />
          </DesktopHideWrapper>
        </InternalHeaderWrapper>
      </Inner>
    </HeaderWrapper>
  )
}

export default Header
