import { theme } from 'styles/themes'

import { JOB_PLATFORM_ACTION_LINK_CANDIDATE } from 'components/Layout/consts'
import { BOOKING_PLATFORM_URL } from 'consts'

export const JOB_PLATFORM_TITLE_NAME = 'Job Platform'
export const BOOKING_PLATFORM_TITLE_NAME = 'Booking Platform'

export const HERO_BUTTONS_MAPPER = {
  [JOB_PLATFORM_TITLE_NAME]: {
    href: JOB_PLATFORM_ACTION_LINK_CANDIDATE,
    backgroundColor: theme.Colors.alert2,
    color: theme.Colors.darkBlue,
  },
  [BOOKING_PLATFORM_TITLE_NAME]: {
    href: BOOKING_PLATFORM_URL,
    backgroundColor: theme.Colors.alert2,
    color: theme.Colors.darkBlue,
  },
}
