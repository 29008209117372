import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from '../../styles/themes'

import BodyBase from './BodyBase'

export const buttonText2Styles = css`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
`

const ButtonText2 = styled(BodyBase)`
  ${buttonText2Styles};
  cursor: pointer;
  ${({ secondary }) => secondary && `color: ${theme.Colors.secondary}`};
`

export default defaultProps({
  as: 'span',
})(ButtonText2)
