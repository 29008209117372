import React from 'react'
import styled from 'styled-components'

import { theme } from 'styles/themes'

import { Subtitle1, Subtitle2 } from 'components/Texts'
import { DualPurposeAnchor } from 'components/Links'

import { useLayout } from '../../context'

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.Colors.light};

  ${({ isSectionTitle }) => isSectionTitle && `margin-top: 24px; `}
`

const MobileOptionItem = ({ label, to, href, handleClick, isSectionTitle }) => {
  const { toggleMobileMenu } = useLayout()
  const TextComponent = isSectionTitle ? Subtitle2 : Subtitle1

  const commonProps = {
    ...(isSectionTitle
      ? { color: theme.Colors.disabledBlack }
      : { medium: true }),
    p: '12px 16px',
    fullWidth: true,
    interactive: !isSectionTitle,
  }

  return (
    <Wrapper isSectionTitle={isSectionTitle} onClick={toggleMobileMenu}>
      {to || href ? (
        <DualPurposeAnchor fullWidth to={to} href={href}>
          <TextComponent {...commonProps}>{label}</TextComponent>
        </DualPurposeAnchor>
      ) : (
        <TextComponent onClick={handleClick} {...commonProps}>
          {label}
        </TextComponent>
      )}
    </Wrapper>
  )
}

export default MobileOptionItem
