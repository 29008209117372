import React from 'react'

import { theme } from 'styles/themes'

import { Subtitle1, Subtitle2 } from 'components/Texts'

const DropdownOptionItem = ({ children, hasSmallerText }) => {
  const TextComponent = hasSmallerText ? Subtitle2 : Subtitle1

  return (
    <TextComponent
      {...(hasSmallerText
        ? { color: theme.Colors.disabledBlack }
        : { medium: true })}
    >
      {children}
    </TextComponent>
  )
}

export default DropdownOptionItem
