import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from 'styles/themes'

import BodyBase from './BodyBase'

export const body1Styles = css`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
`

const Body1 = styled(BodyBase)`
  ${body1Styles};

  cursor: pointer;
  text-decoration: none;
  color: currentColor;
  color: ${theme.Colors.mediumBlack};

  &:hover {
    color: ${theme.Colors.secondary};
  }
`

export default defaultProps({
  as: 'a',
})(Body1)
