import React from 'react'

const SECIcon = ({ width = '148px', height = '30px', ...rest }) => (
  <svg viewBox="0 0 148 30" width={width} height={height} fill="none" {...rest}>
    <path
      d="M3.766 9.923c.432-.274.984-.411 1.657-.411s1.356.117 2.049.353c.692.235 1.242.536 1.647.902l1.235-1.745c-.627-.523-1.372-.928-2.235-1.216a8.204 8.204 0 00-2.608-.431c-1.359 0-2.5.343-3.42 1.03-.922.685-1.383 1.663-1.383 2.93 0 1.268.405 2.21 1.216 2.823.405.327.869.592 1.392.794.522.203 1.287.425 2.293.667 1.007.242 1.716.51 2.128.804.412.294.617.706.617 1.235 0 .53-.219.954-.657 1.274-.438.32-1.029.48-1.774.48-1.49 0-2.928-.6-4.313-1.803L.16 19.354c1.659 1.477 3.56 2.215 5.704 2.215 1.464 0 2.647-.372 3.549-1.117.902-.746 1.353-1.716 1.353-2.912 0-1.195-.357-2.1-1.069-2.715-.713-.614-1.771-1.091-3.176-1.431-1.405-.34-2.323-.657-2.755-.951-.43-.294-.647-.719-.647-1.275 0-.555.216-.97.647-1.245zM24.724 7.708v2.176h-7.312v3.627h6.568v2.06h-6.568v3.666h7.548v2.175h-9.862V7.708h9.626zM37.703 18.913c-.575.267-1.261.402-2.058.402-1.281 0-2.39-.468-3.323-1.402-.935-.934-1.402-2.098-1.402-3.49 0-1.392.47-2.536 1.411-3.431.941-.895 2.105-1.343 3.49-1.343.785 0 1.467.124 2.05.373.58.248 1.198.686 1.852 1.313l1.47-1.607c-1.424-1.569-3.199-2.353-5.322-2.353-2.125 0-3.883.68-5.275 2.039-1.392 1.36-2.087 3.058-2.087 5.097 0 2.04.682 3.725 2.048 5.058 1.366 1.334 3.085 2 5.156 2 2.072 0 3.833-.803 5.284-2.411l-1.49-1.53c-.627.589-1.228 1.017-1.803 1.285zM74.638 15.762v5.647h-2.195v-10.43h2.195v1.902a3.481 3.481 0 011.422-1.51 4.033 4.033 0 012.049-.55c1.646 0 2.764.68 3.352 2.04 1.032-1.36 2.32-2.04 3.862-2.04 1.177 0 2.128.36 2.853 1.079.725.72 1.088 1.758 1.088 3.118v6.39H87.07v-5.724c0-1.921-.798-2.882-2.392-2.882-.746 0-1.396.239-1.951.716-.556.477-.847 1.173-.873 2.088v5.803h-2.196v-5.725c0-.993-.176-1.722-.529-2.186-.353-.464-.909-.696-1.666-.696-.758 0-1.419.252-1.98.755-.563.503-.844 1.238-.844 2.205z"
      fill="#081F2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.85 21.41h2.001v-6.726c0-1.333-.389-2.31-1.167-2.93-.778-.621-1.781-.932-3.009-.932-1.608 0-3.046.503-4.313 1.51l.98 1.372c1.045-.706 2.052-1.059 3.019-1.059 1.542 0 2.314.693 2.314 2.079v.392h-2.843c-1.294 0-2.32.254-3.078.764-.759.51-1.138 1.278-1.138 2.304 0 1.026.356 1.846 1.069 2.46.712.615 1.61.922 2.696.922 1.45 0 2.608-.517 3.47-1.55v1.393zm-.195-4.765v.882c0 .667-.262 1.196-.784 1.588-.523.392-1.134.588-1.833.588-.7 0-1.246-.134-1.637-.402-.393-.268-.589-.656-.589-1.166 0-.993.791-1.49 2.373-1.49h2.47z"
      fill="#081F2D"
    />
    <path
      d="M111.457 13.037c-1.085 0-1.901.353-2.45 1.059-.549.706-.823 1.653-.823 2.843v4.47h-2.196v-10.43h2.196v2.098a4.534 4.534 0 011.44-1.608c.608-.418 1.252-.634 1.931-.647l.02 2.215h-.118zM114.849 21.409h2.196v-2.96l1.588-1.608 3.097 4.568h2.667l-4.255-6.293 3.96-4.137h-2.823l-4.234 4.43V6.862h-2.196V21.41z"
      fill="#081F2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.023 17.154h-8.176c.052.759.399 1.37 1.039 1.834a3.649 3.649 0 002.196.696c1.307 0 2.3-.412 2.98-1.236l1.255 1.373c-1.124 1.164-2.588 1.745-4.392 1.745-1.464 0-2.709-.487-3.734-1.46-1.027-.975-1.539-2.282-1.539-3.922 0-1.64.522-2.944 1.568-3.911 1.045-.967 2.277-1.451 3.695-1.451 1.418 0 2.625.428 3.618 1.284.993.857 1.49 2.036 1.49 3.539v1.51zm-8.176-1.725h5.98c0-.875-.274-1.549-.823-2.019-.55-.47-1.226-.706-2.03-.706-.804 0-1.526.245-2.166.735-.641.49-.961 1.154-.961 1.99z"
      fill="#081F2D"
    />
    <path
      d="M143.139 18.037v-5.294h2.745V10.98h-2.745V7.704h-2.195v3.275h-1.314v1.764h1.314v5.235c0 1.098.329 1.97.99 2.617.659.647 1.457.971 2.392.971.934 0 1.787-.346 2.558-1.04l-.901-1.548c-.432.458-.883.686-1.353.686-.471 0-.837-.147-1.098-.441-.262-.294-.393-.689-.393-1.186z"
      fill="#081F2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.156 2.397c-3.935 4.063-6.397 6.605-10.07 10.392-.945.974-.952 1.491.269 2.185 1.913 1.142 4.32 2.645 6.375 3.874.287.232.647.376 1.044.376.924 0 1.672-.748 1.672-1.672 0-.544-.264-1.022-.667-1.328-.343-.352-.835-.566-1.615-1.029-3.285-1.944-3.332-1.576-.593-4.339 1.964-2.027 3.758-3.868 5.5-5.696.492-.516.649-.84.656-1.625.011-1.283.01-1.282.01-2.517 0-1.537-1.401.16-2.581 1.379"
      fill="#081F2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.193 15.372c-1.913-1.143-4.32-2.646-6.375-3.875a1.657 1.657 0 00-1.045-.376c-.923 0-1.671.748-1.671 1.672 0 .544.264 1.023.666 1.328.344.353.835.567 1.615 1.029 3.285 1.945 3.332 1.577.594 4.34-1.964 2.027-3.758 3.867-5.5 5.695-.492.516-.65.84-.656 1.625-.011 1.283-.011 1.283-.011 2.517 0 1.537 1.402-.16 2.582-1.378 3.934-4.063 6.397-6.606 10.07-10.392.944-.974.952-1.492-.27-2.185z"
      fill="#317FA7"
    />
  </svg>
)

export default SECIcon
