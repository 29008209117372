import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import HeadlineBase from './HeadlineBase'

export const headline1Styles = css`
  font-size: 96px;
  line-height: 1.5;
  letter-spacing: -1.5px;
`

const Headline1 = styled(HeadlineBase)`
  ${headline1Styles};
`

export default defaultProps({
  as: 'h1',
})(Headline1)
