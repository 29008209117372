import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import FooterComponent from './FooterComponent'

const footerQuery = graphql`
  query FooterQuery {
    allContentfulFooter(filter: { portal: { eq: "landing" } }) {
      nodes {
        firstColumnTitle
        firstColumnContent {
          json
        }

        secondColumnTitle
        secondColumnContent {
          json
        }

        thirdColumnTitle
        thirdColumnContent {
          json
        }

        fourthColumnTitle
        fourthColumnContent {
          json
        }
      }
    }
  }
`

const Footer = () => (
  <StaticQuery query={footerQuery} render={FooterComponent} />
)

export default Footer
