import { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'

const useExpand = parentRef => {
  const [state, setState] = useState(false)

  const toggle = useCallback(() => {
    setState(!state)
  }, [state])
  const hide = useCallback(() => {
    setState(false)
  }, [])
  const show = useCallback(() => {
    setState(true)
  }, [])

  useEffect(() => {
    const handleClickOutside = event => {
      const ref = _.get(parentRef, 'current')
      if (ref && !ref.contains(event.target)) {
        hide()
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [parentRef])

  return {
    hide,
    isOpened: state,
    show,
    toggle,
  }
}

export default useExpand
