import React from 'react'
import Img from 'gatsby-image'
import { Flex, Box } from '@rebass/grid'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import _ from 'lodash'

import { Caption, Subtitle2, Headline5 } from 'components/Texts'
import { NoStylesExternalLink } from 'components/Links'

import { Wrapper, CoverImage } from './styledComponents'
import { generateBlogPostPath } from './utils'

const Article = ({
  heroImage,
  slug,
  title,
  description,
  tags = [],
  placeholderImage,
  isMobile,
}) => {
  const pathToBlogPost = generateBlogPostPath(slug)

  return (
    <Wrapper>
      <NoStylesExternalLink href={pathToBlogPost}>
        <CoverImage>
          <Img
            alt={`${title} - hero image`}
            fluid={_.get(heroImage, 'fluid', placeholderImage)}
            style={{
              height: isMobile ? '238px' : '278px',
              borderRadius: '3px',
            }}
          />
        </CoverImage>
      </NoStylesExternalLink>

      <Flex flexDirection="column">
        <Caption mt="24px" mb="8px" bold>
          {tags.join(' & ')}
        </Caption>

        <NoStylesExternalLink href={pathToBlogPost}>
          <Headline5 pt="4px" bold>
            {title}
          </Headline5>
        </NoStylesExternalLink>

        <Box pt="12px" pb="12px">
          <Subtitle2 gray as="div">
            <HTMLEllipsis
              maxLine={3}
              ellipsis="..."
              unsafeHTML={description.childMarkdownRemark.html}
            />
          </Subtitle2>
        </Box>
      </Flex>
    </Wrapper>
  )
}

export default Article
