import React from 'react'

import { DefaultMainBarButton } from '../styledComponents'

const MainBar = ({ toggle, defaultMainBarTitle = '', isOpened }) => (
  <DefaultMainBarButton
    variant="contained"
    isOpened={isOpened}
    onClick={toggle}
  >
    {defaultMainBarTitle}
  </DefaultMainBarButton>
)

export default MainBar
