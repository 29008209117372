import styled, { css } from 'styled-components'

import TypoBase from './TypoBase'

import { theme } from '../../styles/themes'

export const bodyBaseStyles = css`
  font-family: ${theme.Fonts.Family.body};
  font-weight: ${({ medium, bold }) =>
    (medium && theme.Fonts.FontWeight.medium) ||
    (bold && theme.Fonts.FontWeight.bold) ||
    theme.Fonts.FontWeight.regular};

  bold {
    font-weight: ${theme.Fonts.FontWeight.bold};
  }
`

const BodyBase = styled(TypoBase)`
  ${bodyBaseStyles}
`

export default BodyBase
