import React, { useRef } from 'react'

import useExpand from 'hooks/useExpand'

import { DropdownContextProvider } from './DropdownContext'
import { DefaultMainBar, Item } from './components'
import { Wrapper, FloatingBox } from './styledComponents'

const Dropdown = ({
  children,
  MainBar = DefaultMainBar,
  defaultMainBarTitle,
  floatingBoxProps = {},
}) => {
  const dropdownWrapperRef = useRef(null)
  const { isOpened, hide, show, toggle } = useExpand(dropdownWrapperRef)

  return (
    <DropdownContextProvider value={{ isOpened, hide, show, toggle }}>
      <Wrapper ref={dropdownWrapperRef}>
        <MainBar {...{ isOpened, hide, show, toggle, defaultMainBarTitle }} />
        {isOpened && (
          <FloatingBox {...floatingBoxProps}>{children}</FloatingBox>
        )}
      </Wrapper>
    </DropdownContextProvider>
  )
}

Dropdown.Item = Item

export default Dropdown
