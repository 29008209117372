import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import GatsbyImage from 'gatsby-image'

import theme from 'styles/themes'

import { HeadlineBase } from 'components/Texts'
import { Inner } from 'components/Layout'

export const HeroWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`

export const HeroBox = styled(Flex)`
  position: relative;
  flex-direction: column;
  width: 100%;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color || 'inherit'};

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`

export const HeroBoxInnerWrapper = styled(Inner)`
  display: flex;
  position: relative;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    flex-direction: column;
    position: initial;
  }
`

export const HeroBoxContentWrapper = styled(Flex)`
  padding: 128px 0 132px 0;
  flex-direction: column;
  align-items: flex-start;
  max-width: 450px;
  z-index: 1;

  @media (max-width: ${theme.Breakpoints.tablet}) {
    max-width: 410px;
  }

  @media (max-width: ${theme.Breakpoints.mobile}) {
    padding: 8px 0 24px;
    width: 100%;
    max-width: initial;
  }
`

export const HeroTitle = styled(HeadlineBase)`
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.4px;
  }
`

export const HeroButton = styled.button`
  height: 48px;
  border-radius: 4px;
  outline: none;
  border: none;

  padding: 16px 31px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.Colors.secondary};
  color: ${({ color }) => color || 'inherit'};
  cursor: pointer;
  :first-child {
    margin-right: 8px;
  }

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
      color: ${theme.Colors.disabledWhite};
    `}
`

export const Image = styled(GatsbyImage)`
  width: 100%;
  height: 512px;
`
export const ImageMobile = styled(GatsbyImage)`
  width: 100%;
  height: 174px;
`
