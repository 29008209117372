import React, { Fragment } from 'react'

import { ButtonText } from 'components/Texts'
import { DualPurposeAnchor } from 'components/Links'
import Dropdown from 'components/Dropdown'

import OptionItem from './DropdownOptionItem'

const MainBar = ({ toggle, isOpened, mainBarTitle }) => (
  <ButtonText center gray secondary={isOpened} onClick={toggle}>
    {mainBarTitle}
  </ButtonText>
)

const GenericTextDropdown = ({ mainBarTitle, options = [] }) => (
  <Dropdown
    MainBar={props => <MainBar {...props} mainBarTitle={mainBarTitle} />}
    floatingBoxProps={{ style: { padding: '4px 0' } }}
  >
    {options.map(({ label, to, href, handleClick }, index) => (
      /* eslint-disable react/no-array-index-key */
      <Fragment key={index}>
        {to || href ? (
          <DualPurposeAnchor fullWidth to={to} href={href}>
            <Dropdown.Item>
              <OptionItem>{label}</OptionItem>
            </Dropdown.Item>
          </DualPurposeAnchor>
        ) : (
          <Dropdown.Item onClick={handleClick}>
            <OptionItem>{label}</OptionItem>
          </Dropdown.Item>
        )}
      </Fragment>
    ))}
  </Dropdown>
)

export default GenericTextDropdown
