import React from 'react'

const Close = ({
  width = '20px',
  height = '20px',
  color = '#000',
  ...rest
}) => (
  <svg viewBox="0 0 20 20" width={width} height={height} {...rest}>
    <path
      fill={color}
      d="M16.4 5L15 3.6l-5 5-5-5L3.6 5l5 5-5 5L5 16.4l5-5 5 5 1.4-1.4-5-5z"
    />
  </svg>
)

export default Close
