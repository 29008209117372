import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styles/themes'
import { DualPurposeAnchor } from 'components/Links'
import { buttonTextStyles } from 'components/Texts'
import { Box } from '@material-ui/core'
import { PLATFORM_LINKS, PRODUCTS_LINKS_CONTENT } from '../consts'
import { GenericTextDropdown } from './index'

const commonStyles = css`
  ${buttonTextStyles};
  color: ${theme.Colors.mediumBlack};
  font-weight: 500;
`

const StyledAnchor = styled.a`
  ${commonStyles}
`

const AppLinks = () => (
  <Box display="grid" gridAutoFlow="column" gridGap="24px" alignItems="center">
    {PRODUCTS_LINKS_CONTENT.map(({ title, items }) => (
      <GenericTextDropdown mainBarTitle={title} options={items} />
    ))}
    {PLATFORM_LINKS.map(({ to, label, href }) => (
      <DualPurposeAnchor
        key={to || href}
        Anchor={StyledAnchor}
        {...{ to, href }}
      >
        {label}
      </DualPurposeAnchor>
    ))}
  </Box>
)

export default AppLinks
