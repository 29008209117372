import React from 'react'
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
} from 'gatsby-plugin-intl'

const MISSING_TRANSLATION_TEXT = 'Missing Translation!'

class Translation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      stringValue: '',
    }
  }

  componentDidCatch(error) {
    this.setState({ hasError: !!error })
  }

  componentDidMount() {
    const {
      id,
      isString,
      intl: { formatMessage },
    } = this.props

    if (isString) {
      try {
        const stringValue = formatMessage({ id })

        this.setState({ stringValue })
      } catch (error) {
        this.setState({ hasError: true })
      }
    }
  }

  render() {
    const { id, values, hasHtml, isString, defaultMessage } = this.props
    const messageObject = { id, defaultMessage, values }

    if (this.state.hasError || !id) {
      return <span style={{ color: 'red' }}>{MISSING_TRANSLATION_TEXT}</span>
    }

    if (isString) {
      return this.state.stringValue
    }

    if (hasHtml) {
      return <FormattedHTMLMessage {...messageObject} />
    }

    return <FormattedMessage {...messageObject} />
  }
}

export default injectIntl(Translation)
