import { Link } from 'gatsby'
import styled from 'styled-components'

export const NoStylesLink = styled(Link)`
  text-decoration: none;
  color: currentColor;

  ${({ interactive }) => interactive && 'cursor: pointer;'};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
`

export const NoStylesExternalLink = styled.a`
  text-decoration: none;
  color: currentColor;

  ${({ interactive }) => interactive && 'cursor: pointer;'};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
`
