import React, { useContext } from 'react'
import _ from 'lodash'

import { DropdownContext } from '../DropdownContext'
import { ItemWrapper } from '../styledComponents'

const Item = ({
  onClick = _.noop,
  closeOnClick = true,
  children,
  isDisabled,
  ...rest
}) => {
  const { isOpened, hide, show, toggle } = useContext(DropdownContext)

  const handleOnClick = () => {
    onClick({ isOpened, hide, show, toggle })
    if (closeOnClick) {
      hide()
    }
  }

  return (
    <ItemWrapper onClick={handleOnClick} isDisabled={isDisabled} {...rest}>
      {children}
    </ItemWrapper>
  )
}

export default Item
