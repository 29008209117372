import React from 'react'
import Helmet from 'react-helmet'

import { GOOGLE_TAG_MANAGER_KEY } from 'consts'

const GTMTag = () => (
  <div className="TagManger">
    <Helmet>
      <script>
        {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_KEY}');
            `}
      </script>
    </Helmet>
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_KEY}`}
        title="GTM-script"
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  </div>
)

export default GTMTag
